import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Seo from "./../components/Layout/Seo"
import Title from "./../components/Title"
import Blog from "./../components/Blog/Wrapper"

const Wrapper = styled.section`
    position: relative;

    padding: 8rem 0 0;

    ${props => props.theme.above.desktop`
        padding: 20rem 0 0;
    `}
`

const StyledTitle = styled(Title)`
    ${props => props.theme.above.desktop`
        margin-bottom: 12rem;

        .line {
            display: none;
        }
    `}
`

const NewsPage = ({
    pageContext: { lang, langSlug },
    data: {
        news: {
            data: { content_title, content_description },
        },
        posts,
    },
}) => {
    return (
        <Wrapper>
            <StyledTitle
                title={content_title.text}
                description={content_description.text}
                alignment="center"
                text
            />
            <Blog lang={langSlug} data={posts} />
        </Wrapper>
    )
}

export const QUERY_NEWSPAGE = graphql`
    query NewsPage($id: String!, $lang: String!) {
        news: prismicNewsPage(id: { eq: $id }) {
            id
            lang
            ...NewsPageQuery
        }
        posts: allPrismicBlogPost(
            sort: { order: DESC, fields: data___post_date }
            filter: {
                lang: { eq: $lang }
                data: { hide_from_overview: { ne: true } }
            }
        ) {
            nodes {
                uid
                ...BlogPostQuery
            }
        }
    }
`

export default NewsPage
