import React from 'react'
import styled, { css } from 'styled-components'

import Item from './Item'

const StyledSection = styled.section`
    padding: 0 0 1.5rem 0;

    ${props => props.theme.above.desktop`
        padding: 4rem 0 8rem 0;
    `}
`

const List = styled.ul`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 6rem 0;

    padding: 3rem;

    ${props => props.theme.above.desktop`
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 7rem 3rem;

        padding: 0 6rem;

        max-width: 1440px;
        margin: 0 auto;
    `}
`

const Wrapper = ({ data, lang }) => {
    return (
        <StyledSection>
            <List>
                {data.nodes.map((item, i) => {
                    return (
                        <Item key={i} data={item.data} uid={item.uid} lang={lang} />
                    )
                })}
            </List>
        </StyledSection>
    )
}

export default Wrapper
